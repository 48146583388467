
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import dynamic from 'next/dynamic';
const Error = dynamic(() => import('@layouts').then((m) => m.Error, () => null as never));
export default function Custom404Page() {
    return <Error statusCode={404}/>;
}
Custom404Page.pageLayoutConfig = {
    pageViewType: 'Both',
};

    async function __Next_Translate__getStaticProps__1961e688131__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1961e688131__ as getStaticProps }
  